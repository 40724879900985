import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import Image from "next/image";
import "react-chrome-dino-ts/index.css";
const DinoGame = dynamic(() => import("react-chrome-dino-ts"));
type Props = {
  error?: string;
};

const Custom404 = ({ error }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMobile("600");

  //TODO: log errors
  return (
    <div
      style={{
        display: "flex",
        height: `calc(100vh - ${isMobile ? "120" : "200"}px)`,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!isMobile && (
        <div style={{ width: 600 }}>
          <DinoGame hideInstructions />
        </div>
      )}

      <div className="text-center">
        <h1 className="mb-3">
          <Image
            unoptimized
            className="d-inline-block"
            src="https://cdn.molhamteam.com/assets/img/pages/404/404-text.svg"
            alt="Error 404"
            width="250"
            height="250"
          />
          <span className="visually-hidden">{t("error_404")}</span>
        </h1>
        <h2 className="mb-3">{t("page_not_found")}</h2>
        <p className="mb-3">{t("page_not_found_description")}</p>
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a
          href="/"
          className="btn btn-translucent-primary"
          style={{ height: 46 }}
        >
          {t("page_not_found_action")}
        </a>
      </div>
    </div>
  );
};

export default Custom404;
